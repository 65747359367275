import { FC } from 'react';
import styled from '@emotion/styled';
import play from './play.svg';
import pause from './pause.svg';
import TuneBoxTip from './TuneBoxTip';
import { PlayPause } from './styles';

export const ButtonGroup = styled.div`
  display: flex; 
  column-gap: 8px; 
  align-items: center;
  justify-content: space-between;
  @media (min-width: 600px) {
    column-gap: 12px; 
  }
`

export type PlayIconToggleProps = {
  variant?: 'PLAY' | 'PAUSE';
  on?: boolean;
};

const PlayIconToggle: FC<PlayIconToggleProps> = ({variant, on}) => {
  
  const Inner = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
  `

  const Image = styled.img`
    width: 12px; 
    margin-left: 2px; 
    margin-top: 2px; 
  `

  return (
    <>
      {variant === 'PLAY' ? (<Inner><Image src={play} alt="play" /></Inner> ) : (<Inner><Image src={pause} alt="pause" /></Inner> )}
    </>
  )
};

export const TuneBoxControls = ({
  // @ts-ignore
  isPlaying,
  // @ts-ignore
  onPlayPauseClick,
}) => {

return (
<ButtonGroup>
    {/* @ts-ignore */}
    <TuneBoxTip />
    {isPlaying ? (
        <PlayPause
          type="button"
          className="pause"
          onClick={() => onPlayPauseClick(false)}
          aria-label="Pause"
        >
          <PlayIconToggle variant='PAUSE'/>
        </PlayPause>
      ) : (
        <PlayPause
          type="button"
          className="play"
          onClick={() => onPlayPauseClick(true)}
          aria-label="Play"
        >
          <PlayIconToggle variant='PLAY' />
        </PlayPause>
      )}
  </ButtonGroup>
);

};


export default  TuneBoxControls;
