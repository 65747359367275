import styled from '@emotion/styled';
import artistImage from './a-brick-at-a-time.png';
import Home from './game/assets/map/mall.png';
// @ts-ignore
import Song from './ABrickAtATime.mp3';
import { TuneBox } from './TuneBox';
import { Container, Footer, FooterInfo, FooterLink, FooterText, Hidden, SafetyFirstOuter, Enter } from './styles';

import Canvas from './Canvas';

export type PlayIconToggleProps = {
  variant?: 'PLAY' | 'PAUSE';
  on?: boolean;
};


export const NewTuneDaily = styled.h2`
  display: none;
  @media (min-width: 600px) {
    z-index: 2;
    top: 0;
    text-align: center;
    color: black;
    font-size: 12px;
    background: #8de6be;
    padding: 4px 12px; 6px; 
    width: 114px;
    display: inline-block;
    margin: 0;
    margin-bottom: 2px;
    position: absolute;
    right: 0;
    border-bottom-left-radius: 8px;
  }
`

const Tracks = [
  {
    title: "A Brick at a Time",
    artist: "@DJ-Orphan",
    time: "03:44",
    image: artistImage,
    audioSrc: Song,
    artistLink: "https://soundcloud.com/djorphan",
  },
];

function App() {
  
  return (
    <Container>
        <TuneBox tracks={Tracks} />
        <NewTuneDaily>New Tune Daily!</NewTuneDaily>
        <SafetyFirstOuter style={{  filter: 'drop-shadow(0px 0px 48px rgba(0, 0, 0, 1))', position: 'absolute', zIndex: 2, textAlign: 'center', width: '100%', height: '100vh', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
          <div style={{display: 'flex', marginTop: 74, alignItems: 'center', textAlign: 'center', flexDirection: 'column'}}>
            <Enter style={{fontSize: 18, padding: '16px 24px'}} href="https://discord.gg/3bQfUySAqz">Join our Discord</Enter>
          </div>
          <p style={{fontSize: 16, lineHeight: 1.5, textAlign: 'center', color: 'white', width: '30ch' }}>Help <span style={{color: '#FE36F0'}}>SHAPE</span> &amp; <span style={{color: '#36d7ff'}}>CREATE</span> the future of gaming &amp; music</p>
        </SafetyFirstOuter>


        <Canvas id="Viewport" map={Home}></Canvas>
        {/* <Inner>
          <OpenSea href="https://opensea.io/assets/0x282a7d13152b3f51a3e31d46a2ca563f8554d85d/8864" rel="noreferrer">
            <PFP src={rollablazer} alt="rollablazer" />
          </OpenSea>
          <SafetyFirstOuter>
              <SafetyFirstInner src={safetythirst}  alt="Safety, Thirst."/>
              <div style={{display: 'flex', marginTop: 32, alignItems: 'center', textAlign: 'center', flexDirection: 'column'}}>
                <Enter  href="https://discord.gg/3bQfUySAqz">Join our Discord</Enter>
              </div>
              <p style={{fontSize: 13, textAlign: 'center', color: 'black'}}>Help <span style={{color: '#48c9e9'}}>CREATE</span> &amp; <span style={{color: '#e89ee3'}}>SHAPE</span> the future of gaming &amp; music</p>
          </SafetyFirstOuter>
        </Inner> */}
        <Footer>
          {/* <Portfolio>
            <Link href="https://opensea.io/collection/toxic-skulls-club" rel="noreferrer">
              <Skull src={skulls} alt="Toxic Skull Club"/>
            </Link>
            <Link href="https://drugreceipts.com" rel="noreferrer">
              <Dr src={dr} alt="Drug Reciepts"/>
            </Link>
          </Portfolio> */}
          <FooterText>
            <FooterLink href="https://linktr.ee/rollablazer" rel="noreferrer">@rollablazer</FooterLink>
            <FooterInfo><Hidden>&nbsp;/&nbsp;</Hidden>safetythirst.xyz</FooterInfo>
          </FooterText> 
        </Footer>
    </Container>
  );
}

export default App;
