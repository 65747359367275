import { useState, useEffect, useRef } from "react";
import styled from '@emotion/styled';
import TuneBoxControls from "./TuneBoxControls";
import { Artist, Heading, Song, SongBlock, TunePFP } from "./styles";


export const TuneBoxStyles = styled.div`
  padding: 12px;
  line-height: 14px;
  position: absolute;
  box-sizing: border-box;
  width: 100%;
  z-index: 3;
  background: #8de6be;
  box-shadow: inset -2px -2px 0 0 rgba(255 255 255 / 0.4);
  filter: drop-shadow(0px 0px 24px rgba(4, 110, 56, 1));
  border-radius: 0 0 24px;
  @media (min-width: 600px) {
    width: auto;
    padding: 16px 24px;
  }
`

export const TuneBoxInnerStyles = styled.div`
  position: relative; 
  display: flex; 
  gap: 24px; 
  align-items: center;
  justify-content: space-between;
  @media (min-width: 600px) {
    justify-content: start;
    }
`

  // @ts-ignore
  export const TuneBox = ({ tracks }) => {
    // State
    const [trackIndex] = useState(0);
    const [isPlaying, setIsPlaying] = useState(false);
  
    // Destructure for conciseness
    const { title, artist, time, artistLink, image, audioSrc } = tracks[trackIndex];
  
    // Refs
    const audioRef = useRef(new Audio(audioSrc));
    const intervalRef = useRef();
    const isReady = useRef(false);
  
    const startTimer = () => {
      // Clear any timers already running
      clearInterval(intervalRef.current);
      // @ts-ignore
      intervalRef.current = setInterval(() => {
        // @ts-ignore
      }, [1000]);
    };

    useEffect(() => {
      if (isPlaying) {
        audioRef.current.play();
        startTimer();
      } else {
        audioRef.current.pause();
      }
    }, [isPlaying]);
  
    // Handles cleanup and setup when changing tracks
    useEffect(() => {
      audioRef.current.pause();
  
      audioRef.current = new Audio(audioSrc);
  
      if (isReady.current) {
        audioRef.current.play();
        setIsPlaying(true);
        startTimer();
      } else {
        // Set the isReady ref as true for the next pass
        isReady.current = true;
      }
    }, [audioSrc, trackIndex]);
  
    useEffect(() => {
      // Pause and clean up on unmount
      return () => {
        audioRef.current.pause();
        clearInterval(intervalRef.current);
      };
    }, []);
  
    return (
      <TuneBoxStyles>
        <TuneBoxInnerStyles>
          <SongBlock className="track-info">
            <TunePFP
              src={image}
              alt={`track artwork for ${title} by ${artist}`}
            />
            <div>
              <Heading>Artist&nbsp;/&nbsp;<Artist target="_blank" href={artistLink} rel="noreferrer" className="artist">{artist}</Artist></Heading>
              <Song className="title">{title} – <span style={{fontVariantNumeric: 'proportional-nums', fontSize: 10, fontFamily: `source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace`}}>{time}</span></Song>
            </div>
          </SongBlock>
          <TuneBoxControls
            isPlaying={isPlaying}
            onPlayPauseClick={setIsPlaying}
          />
                        
        </TuneBoxInnerStyles>
      </TuneBoxStyles>
    );
  };


export default TuneBoxControls;
