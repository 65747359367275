import { FC, useState } from 'react';
import eth from './eth.svg';
import { TipStyles, TipText, TipMessageStyles } from './styles';

const TuneBoxTip: FC = () => {

  const [copied, setCopied] = useState(false);
  const checkCopied = copied;  
  const ShowMessage = () => setCopied(!copied);
  const CopyEthAddress = () => navigator.clipboard.writeText('0xd6481f61361cEcDF5067191818948aBa312fb55d');

  const copyFunction = () => {
    CopyEthAddress();
    ShowMessage();
  }

  return(
    <div style={{position: 'relative'}}>
      <TipStyles type="button" aria-label="Tip" onClick={copyFunction}>
        <TipText>Tip</TipText>
        <span style={{display: 'flex', width: '10px'}}>
          <img style={{width: '100%'}} src={eth} alt="Tip the DJ in Eth" />
        </span>
      </TipStyles>
      <TipMessageStyles isCopied={checkCopied}>Eth Address Copied</TipMessageStyles>
    </div>
  )
};


export default  TuneBoxTip;
