import styled from '@emotion/styled';

export const Container = styled.div`
  background: #b8ffdd;
  display: flex;
  flex-direction: column;
  height: 100%;
`

export const FooterLink = styled.a`
  cursor: pointer;
  font-size: inherit;
  line-height: inherit;
  color: #FE36F0;
  text-align: center;
  position: relative;
  text-decoration: none;
  transition: .2s linear;
  box-shadow: inset 0 -2px 0 0 #FE36F0;
  padding: 0 4px;
  @media (min-width: 500px) {
    padding: 2px 4px;
  }
  &:after {
    height: 0%;
    content: "";
    background: #FE36F0;
    position: absolute;
    width: 100%;
    left: 0;
    bottom: 0;
    z-index: -1;
    transition: .2s linear;
  }
  &:hover {
    color: rgba(255,255,255,0.8);
    transition: .2s linear;
    &:after {
      height: 100%;
      transition: .2s linear;
    }
  }
`

export const Button = styled.button`
cursor: pointer;
background:  #8de6be;
font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
border-radius: 8px;
border: 1px solid #00000020;
text-transform: uppercase;
font-size: 12px;
font-weight: bold;
box-shadow: inset 0 2px 0 0 #ffffff75;
&:hover {
  background:  #8de6be;
  color: black;
}
&:visited {
  background:  #8de6be;
  color: black;
}
&:active {
  background:  #8de6be;
  color: black;
}
`

export const Inner = styled.div`
  background: #8de6be;
  color: white;
  flex: 1;
  display: flex;
  padding-top: 90px;
  flex-direction: column;
  margin-top: -60px;
  align-items: center;
  padding-bottom: calc(156px + env(safe-area-inset-bottom));
  gap: 32px;
  justify-content: start;
  @media (min-height: 660px) {
    justify-content: center;
    padding-top: 0;
    padding-bottom: calc(16px + env(safe-area-inset-bottom));
  }
  @media (max-height: 800px) {
    padding-top: 0px;
    margin-top: 68px;
  }
`

export const PFP = styled.img`
  width: 190px;
  height: auto;
  border-radius: 190px;
  box-shadow: 0 12px 121px 0 #b6e8cc;
  transition: box-shadow 0.3s ease-in-out;
  @media (min-height: 600px) {
    width: 250px;
    height: auto;
    border-radius: 250px;
  }
`

export const OpenSea = styled.a`
  cursor: pointer;
  display: flex;
  &:hover img { 
    transition: box-shadow 0.3s ease-in-out;
    box-shadow: 0 12px 121px 0 #89d7ab;
  }
`

export const SafetyFirstOuter = styled.div`
  width: 240px;
  @media (min-width: 500px) {
    width: 360px;
  }
  @media (max-height: 500px) {
    width: 240px;
  }
`

export const SafetyFirstInner = styled.img`
    width: 100%;
    height: auto;
    display: 'block';
`

export const Link = styled.a`
  cursor: pointer;
  display: flex;
  width: 48px;
  height: 48px;
  @media (min-width: 500px) {
    width: 52px;
    height: 52px;
  }
  @media (max-height: 800px) {
    width: 38px;
    height: 38px;
  }
`

export const Skull = styled.img`
  width: 100%;
  height: auto;
`
export const Dr = styled.img`
width: 100%;
height: auto;
`

export const Portfolio = styled.div`
  display: flex;
  gap: 24px;
  @media (max-height: 800px) {
    gap: 12px;
    padding-left: 8px;
  }
`

export const Footer = styled.footer`
  position: fixed;
  z-index: 3;
  width: 100%;
  bottom: 0;
  padding: 16px 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  flex-direction: column;
  color: white;
  gap: 8px;

`


export const FooterText = styled.p`
  display: flex;
  flex-direction: column;
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
  font-size: 10px;
  text-align: center;
  margin: 0;
  @media (min-width: 500px) {
    display: block;
    font-size: 11px;
  }
`

export const FooterInfo = styled.span`
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
  @media (min-width: 500px) {
    font-size: inherit;
  }
`

export const Hidden = styled.span`
  display: none;
  @media (min-width: 500px) {
    display: inline-block;
  }
`

export const Enter = styled.a`
  cursor: pointer;
  background:  black;
  color: white;
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
  border: 1px solid #00000020;
  text-transform: uppercase;
  font-weight: bold;
  box-shadow: inset 0 2px 0 0 #ffffff75;
  padding: 12px 24px;
  font-size: 16px;
  &:hover {
    background:  black;
    color: white;
  }
  &:visited {
    background:  black;
    color: white;
  }
  &:active {
    background:  black;
    color: white;
  }

  text-decoration: none;
  border-radius: 40px;
  `


export const Tunes = styled.div`
  padding: 24px;
  display: grid;
  gap: 24px;
  grid-template-columns: 1fr auto;
  line-height: 14px;
  position: absolute;
  justify-content: space-between;
`



export const PlayPause = styled(Button)`
  height: 40px;
  width: 40px;
  border-radius: 40px;
  display: flex;
  align-items: center;
  overflow: hidden;
  position: relative;
  justify-content: center;
`



export const Heading = styled.p`
  font-size: 10px;
  font-weight: 400;
  display: flex;
  align-items: center;
  margin: 0;
  text-transform: uppercase;
`

export const Artist = styled(FooterLink)`
  font-size: 10px;
  font-weight: bold;
  margin: 0;
  color: #FE36F0;
  padding: 2px 0;
  margin-bottom: 2px;
  position: sticky;
  @media (min-width: 500px) {
    font-size: 11px;
    padding: 2px 0;
}
  @media (min-width: 600px) {
      font-size: 12px;
      padding: 2px 0;
  }
`

export const Song = styled.p`
  font-size: 12px;
  margin: 0;
  font-family: 'Helvetica Neue';
  font-weight: 600;
  color: #44725c;
  & > span {
    font-weight: 400;
  }
  `

export const TunePFP = styled.img`
  width: 42px;
  height: 42px;
  @media (min-width: 600px) {
    width: 48px;
    height: 48px;
}
`

export const SongBlock = styled.div`
display: flex;
align-items: center;
gap: 8px;
`

export const TipStyles = styled(Button)(() => ({
    minWidth: 50,
    padding: '11px 12px 12px',
    display: 'flex',
    gap: '8px',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'relative',
  })
);

export const  TipMessageStyles = styled.div(
  ({ isCopied }: { isCopied?: boolean; }) => ({
    position: 'absolute',
    bottom: '-31px',
    width: '120px',
    right: 0,
    background: 'black',
    color: 'white',
    padding: '6px 6px',
    borderRadius: 6,
    fontSize: 9,
    lineHeight: '12px',
    transition: 'opacity 0.4s ease-in-out',
    pointerEvents: 'none',
    textAlign: 'center',
    opacity: 0,
    boxSizing: 'border-box',
    ...(isCopied && {
      opacity: 1,
    }),
    '@media (min-width: 600px)': {
        width: '100%',
    },
  })
);



export const TipText = styled.span`
  display: none;
  @media (min-width: 500px) {
      display: inline-block;
      margin-top: 2px;
  }
`
