import styled from '@emotion/styled';

export type CharacterProps = {
  src?: string;
  people?: any;
  walls?: any;
  id?: string;
  person?: string;
  direction?: 'UP' | 'RIGHT' | 'DOWN' | 'LEFT';
  top?: number;
  left?: number;
};

const CharacterEl = styled.div<CharacterProps>(({ top = 0, left = 0, direction = 'DOWN', src }) => ({
  width: 128, 
  height: 128, 
  position: 'absolute',
  top: top +  (128 / 2),
  left: left,
  backgroundImage: `url("${src}")`,
  backgroundRepeat: 'no-repeat',
  backgroundSize: 128 * 4,
  imageRendering: 'crisp-edges',
  backgroundPosition: `0 0`,
  zIndex: 1,
  filter: 'drop-shadow(0 1px 0 rgb(0 0 0 / 0.8))'
}));

export const Character = ({ id, src, person, direction, top, left, ...props }: CharacterProps) => {
  return (
    <CharacterEl 
      src={src} 
      id={id} 
      person={person} 
      direction={direction} 
      left={left} 
      top={top}
      {...props} 
    />
  );
};

export default Character;