import React, { ReactNode } from 'react';
import styled from '@emotion/styled';
import Character from './Character';
import CharacterImage from './game/assets/character.png';

export type CanvasProps = {
  id?: string;
  children?: ReactNode;
  map?: string;
};

const CanvasEl = styled.canvas<CanvasProps>(({map}) => ({
  width: '2432px', 
  height: '3584px', 
  imageRendering: 'crisp-edges',
  backgroundImage: `url("${map}")`,
  backgroundColor: 'transparent',
  position: 'relative',
  backgroundRepeat: 'no-repeat',
  backgroundSize: '2432px 3584px',
  top: 0,
  left: 0,
}));

const OuterEl = styled.div(() => ({
  width: '100%', 
  height: '100vh',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  overflow: 'hidden',
  position: 'absolute',
  '&:before': {
    content: `''`,
    background: 'transparent',
    boxShadow: 'inset 0 0 96px 60px rgb(0 0 0 / 0.7)',
    mixBlendMode: 'darken',
    width: '100%',
    height: '100vh',
    zIndex: 1,
    display: 'block',
    position: 'absolute',
  }
}));

const InnerEl = styled.div(() => ({
  width: '2432px', 
  height: '3584px', 
  position: 'relative'
}));
  
export const Canvas = ({ children, ...props }: CanvasProps) => {
  return (
    <OuterEl>
      <InnerEl>
        <Character top={128 * 12} left={128 * 9} src={CharacterImage} />
        <CanvasEl
          {...props} 
        >
            {children}
        </CanvasEl>
      </InnerEl>
    </OuterEl>
  );
};

export default Canvas;